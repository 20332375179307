import React from 'react';
import { Helmet } from 'react-helmet-async';

function AGB() {
  return (
    <div className="container mx-auto px-6 py-8">
      <Helmet>
        <title>Allgemeine Geschäftsbedingungen - Eventservice Taeger</title>
        <meta
          name="description"
          content="AGB des Eventservice Taeger für die Vermietung von Partyequipment und Fotoservice SnapUp!"
        />
        <link rel="canonical" href="https://eventservice-taeger.de/agb" />
      </Helmet>

      <h1 className="text-3xl font-bold mb-6">Allgemeine Gesch&auml;fts&shy;bedingungen (AGB)</h1>

      <div className="prose max-w-none text-gray-700">
        <h2 className="text-2xl font-bold mt-8 mb-4">1. Geltungsbereich und Vertragsabschluss</h2>
        <p className="mb-4">
          1.1 Diese Allgemeinen Gesch&auml;ftsbedingungen gelten f&uuml;r die Vermietung von Cocktailmaschinen, Beamern, Partyzelten, Stehtischen (mit Hussen), Bierzeltgarnituren sowie f&uuml;r den Fotoservice SnapUp! durch Eventservice Taeger.
        </p>
        <p className="mb-4">
          1.2 Die Vermietung erfolgt ausschlie&szlig;lich an Kunden in Deutschland.
        </p>
        <p className="mb-4">
          1.3 Der Vertrag kommt mit der schriftlichen Zustimmung zum Auftrag zustande.
        </p>
        <p className="mb-4">
          1.4 Der Mieter verpflichtet sich, den Mietgegenstand bei Abholung oder Lieferung auf Vollst&auml;ndigkeit und sichtbare M&auml;ngel zu pr&uuml;fen. Festgestellte M&auml;ngel sind sofort zu melden und werden dokumentiert. Sp&auml;tere Reklamationen sind ausgeschlossen.
        </p>
        <p className="mb-4">
          1.5 Angebote sind 14 Tage g&uuml;ltig und freibleibend. W&auml;hrend dieser Zeit bleibt die Reservierung bestehen. Nach Ablauf ist eine neue Anfrage erforderlich. Nach Ablauf dieser Frist ist der Vermieter berechtigt, den Mietgegenstand anderweitig zu vergeben.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">2. Mietgegenst&auml;nde und Nutzung</h2>
        <p className="mb-4">
          2.1 Der Mieter verpflichtet sich, die Mietgegenst&auml;nde sorgf&auml;ltig zu behandeln und ausschlie&szlig;lich gem&auml;&szlig; ihrer vorgesehenen Nutzung einzusetzen.
        </p>
        <p className="mb-4">
          2.2 Jegliche Ver&auml;nderungen, Umbauten oder Beklebungen der Mietgegenst&auml;nde sind untersagt.
        </p>
        <p className="mb-4">
          2.3 Beamer d&uuml;rfen ausschlie&szlig;lich in wettergesch&uuml;tzten Bereichen genutzt werden. Die Cocktailmaschine muss an einem wetterfesten Standort aufgestellt werden.
        </p>
        <p className="mb-4">
          2.4 Der Mieter tr&auml;gt die Verantwortung f&uuml;r einen geeigneten Aufstellort der Mietgegenst&auml;nde. Sollte eine Aufstellung aufgrund ungeeigneter Bedingungen nicht m&ouml;glich sein, wird die Miete dennoch voll berechnet und die Mietgegenst&auml;nde wieder mitgenommen.
        </p>
        <p className="mb-4">
          2.5 Die spezifischen Anforderungen f&uuml;r Partyzelte ergeben sich aus den jeweiligen Mietvertr&auml;gen.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">3. Mietdauer, Verl&auml;ngerung, R&uuml;ckgabe und Reinigung</h2>
        <p className="mb-4">
          3.1 Die Mietdauer endet mit dem vereinbarten R&uuml;ckgabetermin. Eine Verl&auml;ngerung ist nur nach vorheriger Absprache m&ouml;glich und erfolgt zu den auf der Website angegebenen Tagespreisen (24-Stunden-Abrechnung).
        </p>
        <p className="mb-4">
          3.2 Der Mieter ist verpflichtet, die Mietgegenst&auml;nde in gereinigtem Zustand zur&uuml;ckzugeben. Andernfalls wird eine Reinigungspauschale von 40 Euro pro Stunde (Mindestdauer: 1 Stunde) berechnet.
        </p>
        <p className="mb-4">
          3.3 Versp&auml;tete R&uuml;ckgaben f&uuml;hren zu zus&auml;tzlichen Mietkosten gem&auml;&szlig; den festgelegten Tagespreisen.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">4. Transport und Aufstellung</h2>
        <p className="mb-4">
          4.1 Die Mietgegenst&auml;nde sind grunds&auml;tzlich vom Mieter abzuholen. Eine Lieferung kann gesondert vereinbart werden.
        </p>
        <p className="mb-4">
          4.2 Der Mieter hat sicherzustellen, dass der Aufstellort den technischen Anforderungen entspricht.
        </p>
        <p className="mb-4">
          4.3 Aufgrund der Mietbedingungen kann der Vermieter bei extremen Wetterbedingungen besondere Sicherheitsma&szlig;nahmen vorschreiben (z. B. vollst&auml;ndiger Verschluss der Zeltseiten). Sollte eine Aufstellung aufgrund von Witterungsbedingungen nicht m&ouml;glich sein, entf&auml;llt die Mietgeb&uuml;hr.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">5. Stornierung und Vertragsr&uuml;cktritt</h2>
        <p className="mb-4">
          5.1 Stornierungen m&uuml;ssen schriftlich erfolgen. Es gelten folgende Stornierungsgeb&uuml;hren f&uuml;r alle Mietgegenst&auml;nde au&szlig;er der Cocktailmaschine:<br />
          - Bis 30 Tage vor Mietbeginn: 15 % des vereinbarten Gesamtmietpreises<br />
          - Bis 14 Tage vor Mietbeginn: 30 % des vereinbarten Gesamtmietpreises<br />
          - Bis 7 Tage vor Mietbeginn: 60 % des vereinbarten Gesamtmietpreises<br />
          - Bis 3 Tage vor Mietbeginn: 80 % des vereinbarten Gesamtmietpreises<br />
          - Weniger als 3 Tage vor Mietbeginn: 100 % des vereinbarten Gesamtmietpreises
        </p>
        <p className="mb-4">
          5.2 F&uuml;r die Cocktailmaschine gelten folgende Stornierungsbedingungen:<br />
          - Bis 30 Tage vor Mietbeginn: 15 % des Basispaketpreises<br />
          - Bis 14 Tage vor Mietbeginn: 30 % des Basispaketpreises<br />
          - Bis 7 Tage vor Mietbeginn: 60 % des Basispaketpreises<br />
          - Bis 3 Tage vor Mietbeginn: 80 % des Basispaketpreises sowie 50 % des geplanten Mehrverbrauchs<br />
          - Weniger als 3 Tage vor Mietbeginn: 100 % des Basispaketpreises sowie 50 % des geplanten Mehrverbrauchs
        </p>
        <p className="mb-4">
          5.3 Der Vermieter ist berechtigt, bei h&ouml;herer Gewalt vom Vertrag zur&uuml;ckzutreten.
        </p>
        <p className="mb-4">
          5.4 Eine vorzeitige R&uuml;ckgabe der Mietgegenst&auml;nde enth&auml;ndet nicht von der Zahlung des vollen Mietpreises.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">6. Haftung und Versicherung</h2>
        <p className="mb-4">
          6.1 Der Mieter haftet f&uuml;r s&auml;mtliche Sch&auml;den, die w&auml;hrend der Mietdauer entstehen, unabh&auml;ngig davon, ob diese durch ihn oder Dritte verursacht wurden.
        </p>
        <p className="mb-4">
          6.2 Der Vermieter stellt keine Versicherung zur Verf&uuml;gung. Der Mieter ist f&uuml;r eine angemessene Versicherung selbst verantwortlich.
        </p>
        <p className="mb-4">
          6.3 Im Falle eines Verlusts oder einer Besch&auml;digung ist der Mieter verpflichtet, die Kosten f&uuml;r eine gleichwertige oder neuwertige Ersatzbeschaffung zu tragen.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">7. Datenschutzbestimmungen f&uuml;r SnapUp!</h2>
        <p className="mb-4">
          7.1 Der Fotoservice SnapUp! speichert Bilder bis nach Ablauf des vereinbarten Fotozeitraums.
        </p>
        <p className="mb-4">
          7.2 Die Bilder werden ausschlie&szlig;lich f&uuml;r den jeweiligen Kunden gespeichert und nicht an Dritte weitergegeben.
        </p>
        <p className="mb-4">
          7.3 Die Server befinden sich in Herford und sind lokal gesichert sowie verschl&uuml;sselt.
        </p>
        <p className="mb-4">
          7.4 Eine Nutzung der Fotos durch den Vermieter oder andere erfolgt nicht.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">8. Zahlungsbedingungen</h2>
        <p className="mb-4">
          8.1 Bei der Cocktailmaschine ist der Preis des Basispakets bis 7 Tage vor Veranstaltungsbeginn per &Uuml;berweisung zu zahlen.
        </p>
        <p className="mb-4">
          8.2 Der Mehrverbrauch der Cocktailmaschine wird ebenfalls innerhalb von 14 Tagen auf Rechnung abgerechnet.
        </p>
        <p className="mb-4">
          8.3 Das Angebot f&uuml;r eine Cocktailmaschine umfasst eine optionale Anzahl zus&auml;tzlicher Cocktails (ab dem 101. Cocktail). Mit der Zustimmung zum Angebot garantiert der Kunde die Abnahme von mindestens 50 % der optionalen Cocktails.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">9. Schlussbestimmungen</h2>
        <p className="mb-4">
          9.1 Es gilt ausschlie&szlig;lich deutsches Recht.
        </p>
        <p className="mb-4">
          9.2 Gerichtsstand ist der Sitz des Vermieters.
        </p>
        <p className="mb-4">
          9.3 <strong>Salvatorische Klausel:</strong> Sollte eine Bestimmung dieser AGB unwirksam oder nicht durchsetzbar sein, bleibt die G&uuml;ltigkeit der &uuml;brigen Bestimmungen unber&uuml;hrt.
        </p>
        <p className="mb-4">
          9.4 M&uuml;ndliche Nebenabsprachen bestehen nicht. S&auml;mtliche Vereinbarungen bed&uuml;rfen der Schriftform.
        </p>

        <p className="mt-8 text-gray-600">
          Stand: {new Date().toLocaleDateString('de-DE', { day: '2-digit', month: 'long', year: 'numeric' })}
        </p>
      </div>
    </div>
  );
}

export default AGB;