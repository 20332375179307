import React, { useState, useEffect , useMemo} from 'react';
import { Helmet } from 'react-helmet-async';

import partyzelt5x8_0 from '../images/partyzelt_5x8_0.JPG';
import partyzelt5x8_1 from '../images/partyzelt_5x8_1.JPG';
import partyzelt5x8_2 from '../images/partyzelt_5x8_2.JPG';
import partyzelt5x8_3 from '../images/partyzelt_5x8_3.JPG';
import partyzelt5x8_4 from '../images/partyzelt_5x8_4.JPG';
import partyzelt5x8_5 from '../images/partyzelt_5x8_5.JPG';
import partyzelt5x8_6 from '../images/partyzelt_5x8_6.JPG';
import partyzelt5x8_7 from '../images/partyzelt_5x8_7.JPG';
import partyzelt5x8_8 from '../images/partyzelt_5x8_8.JPG';
import partyzelt5x8_9 from '../images/partyzelt_5x8_9.JPG';
import partyzelt5x8_10 from '../images/partyzelt_5x8_10.JPG';
import partyzelt5x8_11 from '../images/partyzelt_5x8_11.JPG';

function Partyzelte() {
  const [expandedCard, setExpandedCard] = useState(null);
  const [currentImageIndices, setCurrentImageIndices] = useState({});
  const [autoplayEnabled, setAutoplayEnabled] = useState({});
  const [initialized, setInitialized] = useState(false);

  const tents = useMemo(() => [
    {
      id: 1,
      title: 'Partyzelt 5x8m',
      images: [
        partyzelt5x8_1,
        partyzelt5x8_0,
        partyzelt5x8_2,
        partyzelt5x8_3,
        partyzelt5x8_11,
        partyzelt5x8_4,
        partyzelt5x8_5,
        partyzelt5x8_6,
        partyzelt5x8_10,
        partyzelt5x8_7,
        partyzelt5x8_8,
        partyzelt5x8_9,
      ],
      teasertext: 'Unser stabiles 5x8 Meter Partyzelt mit Sturmsicherung - perfekt für mittelgroße Veranstaltungen mit bis zu 80 Gästen.',
      shortDescription: '',
      description:
        'Unser 5x8m Partyzelt überzeugt durch seine robuste Bauweise, die zuverlässigen Schutz vor Wind, Regen und Zugluft bietet. Es ist die ideale Lösung für jede Veranstaltung – ob Hochzeit, Geburtstag oder Firmenfeier. Mit Platz für bis zu 80 Gäste oder 6-8 Bierzeltgarnituren schafft es eine einladende Atmosphäre und sorgt dafür, dass Ihre Feier unvergesslich wird.',
      technicalData: {
        material: '38 mm starke Stahlrohre, vollverschraubt, verstärkte PVC-Plane (ähnlich LKW-Plane)',
        witterungsbeständigkeit: 'Wind, Zugluft, Regen, UV-Schutz',
        öffnungen: 'Türen an der Stirnseiten, Seitenteile können freigelassen werden',
        beleuchtung: 'optionales Lichtpaket',
      },
      prices: [
        'Preis pro 24 Stunden: 60 Euro',
        'Wochenendpaket (Fr-Mo): 140 Euro',
        'Längere Zeiten und Preise auf Anfrage',
        'Kaution: 300 Euro',
      ],
      optional: [
        'Lichtpaket: 20 Euro',
        'Lieferung/Abholung (10 km um Herford): 30 Euro',
        'Lieferung/Abholung (mehr als 10 km): Auf Anfrage',
        'Aufbau/Abbau (2-3 Helfer müsst ihr stellen): 120 Euro',
      ],
      packMass: [
        '1 Tasche: 65x55x15 cm',
        '2 Taschen: 65x55x28 cm',
        '1 Tasche: 123x28,5x15 cm',
        '2 Taschen: 196x20x10,5 cm',
        '1 Tasche: 196x27x12 cm',
        '1 Eurobox: 40x60x30 cm',
      ],
      additionalInfo:
        'Das Zelt bietet Platz für bis zu 80 Gäste (stehend) oder 6-8 normale Bierzeltgarnituren. Abholung und Rückgabe des Zeltes in 32051 Herford. Die Rückgabe muss in trockenem und sauberem Zustand erfolgen. Bei eigenem Aufbau seid ihr für die ordnungsgemäße Sicherung verantwortlich. Wir übernehmen keine Haftung für Sach- oder Personenschäden.',
      mietdokument: '/downloads/Mietvertrag_Mietbedingungen_Zelte.pdf',
    },
  ], []);

  useEffect(() => {
    if (!initialized) {
      const initialIndices = {};
      const initialAutoplay = {};
      
      tents.forEach(tent => {
        initialIndices[tent.id] = 0;
        initialAutoplay[tent.id] = true;
      });
      
      setCurrentImageIndices(initialIndices);
      setAutoplayEnabled(initialAutoplay);
      setInitialized(true);
    }
  }, [initialized, tents]);

  useEffect(() => {
    if (!initialized) return;

    const intervals = {};
    
    tents.forEach(tent => {
      if (autoplayEnabled[tent.id]) {
        intervals[tent.id] = setInterval(() => {
          setCurrentImageIndices(prev => ({
            ...prev,
            [tent.id]: (prev[tent.id] + 1) % tent.images.length
          }));
        }, 10000);
      }
    });

    return () => {
      Object.values(intervals).forEach(interval => clearInterval(interval));
    };
  }, [autoplayEnabled, initialized, tents]);

  const handleImageSelect = (tentId, imageIndex) => {
    setCurrentImageIndices(prev => ({
      ...prev,
      [tentId]: imageIndex
    }));
    setAutoplayEnabled(prev => ({
      ...prev,
      [tentId]: false
    }));
  };

  if (!initialized) {
    return null;
  }

  return (
    <div>
      <Helmet>
        <title>Partyzelt 5x8m mieten - Ideal für Hochzeiten & Firmenfeiern | Eventservice Taeger</title>
        <meta name="description" content="Mieten Sie das wetterfeste 5x8 Meter Partyzelt von Eventservice Taeger. Perfekt für Hochzeiten, Geburtstage und Firmenfeiern. Schutz vor Wind und Wetter, Platz für bis zu 80 Gäste." />
        <meta name="keywords" content="Partyzelt mieten, 5x8 Meter Partyzelt, Zeltverleih Herford, Hochzeitszelt, Firmenfeier Zelt, wetterfestes Partyzelt" />
        <link rel="canonical" href="https://eventservice-taeger.de/partyzelte" />
      </Helmet>

      <div className="container mx-auto px-6 py-6 text-center">
        <h1 className="text-black text-4xl font-bold mb-4">Partyzelte</h1>
        <p className="text-black text-xl">
          Dein Schutz für jedes Wetter, deine Bühne für jede Party!
        </p>
      </div>

      <div className="container mx-auto px-6 py-6">
        <div className="space-y-8">
          {tents.map((tent) => (
            <div key={tent.id} className="border rounded-lg shadow-md overflow-hidden">
              <div className="flex flex-col md:flex-row">
                {/* Linke Seite mit Bild und Thumbnails */}
                <div className="w-full md:w-2/5">
                  <div className="relative bg-white p-4">
                    <div className="image-4by3 w-full relative pb-[75%] overflow-hidden">
                      <img
                        src={tent.images[currentImageIndices[tent.id]]}
                        alt={`${tent.title} - Bild ${currentImageIndices[tent.id] + 1}`}
                        className="absolute top-0 left-0 w-full h-full object-cover"
                        width="960"
                        height="720"
                      />
                    </div>
                  </div>
                  
                  {expandedCard === tent.id && (
                    <div className="p-4">
                      <div className="grid grid-cols-4 gap-2">
                        {tent.images.map((img, index) => (
                          <div key={index} className="relative pb-[75%]">
                            <img
                              src={img}
                              alt={`${tent.title} Bild ${index + 1}`}
                              className={`absolute top-0 left-0 w-full h-full object-cover cursor-pointer rounded ${
                                currentImageIndices[tent.id] === index ? 'border-2 border-blue-600' : 'border border-gray-200'
                              }`}
                              onClick={() => handleImageSelect(tent.id, index)}
                              width="100"
                              height="75"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                {/* Rechte Seite mit Informationen */}
                <div className="w-full md:w-3/5 p-6">
                  <div className="flex flex-col">
                    {/* Basis-Informationen (immer sichtbar) */}
                    <div>
                      <h2 className="text-2xl font-bold mb-2">{tent.title}</h2>
                      <p className="text-gray-700 mb-2">{tent.teasertext}</p>
                      <button
                        onClick={() => setExpandedCard(expandedCard === tent.id ? null : tent.id)}
                        className="text-blue-600 hover:underline text-lg mb-4"
                      >
                        {expandedCard === tent.id ? 'Weniger anzeigen' : 'Mehr erfahren'}
                      </button>
                    </div>

                    {/* Erweiterte Informationen */}
                    {expandedCard === tent.id && (
                      <div className="space-y-6">
                        <div>
                          <h3 className="text-xl font-bold mb-2">Beschreibung</h3>
                          <p className="text-gray-700">{tent.description}</p>
                        </div>

                        <div>
                          <h3 className="text-xl font-bold mb-2">Technische Daten</h3>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                            {Object.entries(tent.technicalData).map(([key, value], index) => (
                              <div key={index} className="text-gray-700">
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>{' '}
                                {value}
                              </div>
                            ))}
                          </div>
                        </div>

                        <div>
                          <h3 className="text-xl font-bold mb-2">Preise</h3>
                          <ul className="list-disc list-inside text-gray-700">
                            {tent.prices.map((price, index) => (
                              <li key={index}>{price}</li>
                            ))}
                          </ul>
                        </div>

                        <div>
                          <h3 className="text-xl font-bold mb-2">Optionales Zubehör</h3>
                          <ul className="list-disc list-inside text-gray-700">
                            {tent.optional.map((option, index) => (
                              <li key={index}>{option}</li>
                            ))}
                          </ul>
                        </div>

                        <div>
                          <h3 className="text-xl font-bold mb-2">Packmaß</h3>
                          <ul className="list-disc list-inside text-gray-700">
                            {tent.packMass.map((mass, index) => (
                              <li key={index}>{mass}</li>
                            ))}
                          </ul>
                        </div>

                        <div>
                          <h3 className="text-xl font-bold mb-2">Mietdokumente</h3>
                          <a
                            href={tent.mietdokument}
                            download
                            className="text-blue-600 hover:underline"
                          >
                            Mietvertrag und Mietbedingungen herunterladen
                          </a>
                        </div>

                        <div className="flex space-x-4">
                          <a
                            href="/kontakt"
                            className="bg-gray-600 text-white font-semibold px-6 py-3 rounded-md hover:bg-gray-700"
                          >
                            Kontakt aufnehmen
                          </a>
                          <a
                            href={`https://wa.me/4915164696160?text=Ich%20interessiere%20mich%20für%20das%20Partyzelt%20${encodeURIComponent(
                              tent.title
                            )}.`}
                            className="bg-green-600 text-white font-semibold px-6 py-3 rounded-md hover:bg-green-700"
                          >
                            Per WhatsApp kontaktieren
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="border rounded-lg shadow-md p-6 text-center mt-8">
          <h2 className="text-2xl font-bold mb-4">Wir erweitern unser Angebot!</h2>
          <p className="text-gray-700 mb-4">
            Unser Angebot wird stetig erweitert. Sollte das passende Zelt aktuell
            nicht dabei sein, schauen Sie gerne in Kürze noch einmal vorbei – arbeiten ständig an neuen Lösungen, um Ihre Veranstaltung perfekt zu
           machen!
         </p>
       </div>
     </div>
   </div>
 );
}

export default Partyzelte;