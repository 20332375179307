import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight, FaPlayCircle } from 'react-icons/fa';

// Bilder importieren
import image1 from '../images/cocktail_1.JPG';
import image2 from '../images/cocktail_2.JPG';
import image3 from '../images/cocktail_3.JPG';
import image4 from '../images/cocktail_4.JPG';
import image5 from '../images/cocktail_5.JPG';
import image6 from '../images/cocktail_6.JPG';
import image7 from '../images/cocktail_7.JPG';
import image8 from '../images/cocktail_8.JPG';

// Medien-Array mit Bildern und Video
const mediaItems = [
  { type: 'image', src: image1 },
  { type: 'image', src: image2 },
  { type: 'image', src: image3 },
  { type: 'video', src: 'cRURSkbWqcM' }, // YouTube Video ID
  { type: 'image', src: image4 },
  { type: 'image', src: image5 },
  { type: 'image', src: image6 },
  { type: 'image', src: image7 },
  { type: 'image', src: image8 },
];

function CarouselSection() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        handleNext();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [activeIndex, isPaused]);

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
    setIsPaused(true);
  };

  const handleNext = () => {
    setActiveIndex((prev) => (prev + 1) % mediaItems.length);
    setIsPaused(true);
  };

  const handlePrev = () => {
    setActiveIndex((prev) => (prev === 0 ? mediaItems.length - 1 : prev - 1));
    setIsPaused(true);
  };

  const renderMainContent = () => {
    const item = mediaItems[activeIndex];
    
    if (item.type === 'video') {
      return (
        <div className="relative w-full aspect-[4/3]">
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${item.src}?autoplay=1&mute=1`}
            className="w-full h-full rounded-lg"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      );
    }
    
    return (
      <img
        src={item.src}
        alt={`Cocktailmaschine Bild ${activeIndex + 1}`}
        className="w-full aspect-[4/3] object-cover rounded-lg"
        loading="lazy"
      />
    );
  };

  return (
    <div className="container mx-auto px-6 py-6 flex flex-col md:flex-row items-start gap-8">
      {/* Hauptanzeige */}
      <div className="relative bg-white shadow-lg rounded-lg p-4 md:flex-1 w-full">
        {renderMainContent()}
        
        <button
          onClick={handlePrev}
          aria-label="Vorheriges Bild"
          className="absolute left-4 top-1/2 -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-black p-3 rounded-full shadow-md"
        >
          <FaArrowLeft size={20} />
        </button>
        
        <button
          onClick={handleNext}
          aria-label="Nächstes Bild"
          className="absolute right-4 top-1/2 -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-black p-3 rounded-full shadow-md"
        >
          <FaArrowRight size={20} />
        </button>
      </div>

      {/* Thumbnail-Grid */}
      <div className="bg-white shadow-lg rounded-lg p-4 md:flex-1 w-full">
        <div className="grid grid-cols-4 gap-4">
          {mediaItems.map((item, index) => (
            <div
              key={index}
              className="relative h-24 cursor-pointer group"
              onClick={() => handleThumbnailClick(index)}
            >
              {/* Video Overlay */}
              {item.type === 'video' && (
                <div className="absolute inset-0 bg-black/30 flex items-center justify-center rounded-lg">
                  <FaPlayCircle className="text-white text-3xl opacity-90" />
                </div>
              )}
              
              <img
                src={item.type === 'video' 
                  ? `https://img.youtube.com/vi/${item.src}/mqdefault.jpg`
                  : item.src
                }
                className="w-full h-full object-cover object-center rounded-lg border-2 border-gray-200"
                alt={`Thumbnail ${index + 1}`}
                loading="lazy"
              />
              
              {/* Aktiver Rahmen */}
              {activeIndex === index && (
                <div className="absolute inset-0 border-4 border-blue-500 rounded-lg pointer-events-none" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CarouselSection;