import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

// Import der Seiten ohne Lazy Loading
import Start from './pages/Start';
import Partyzelte from './pages/Partyzelte';
import Cocktailmaschine from './pages/Cocktailmaschine';
import Equipment from './pages/Equipment';
import Kontakt from './pages/Kontakt';
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';
import UeberUns from './pages/UeberUns';
import AGB from './pages/AGB';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Start />} />
          <Route path="/partyzelte" element={<Partyzelte />} />
          <Route path="/cocktailmaschine" element={<Cocktailmaschine />} />
          <Route path="/equipment" element={<Equipment />} />
          <Route path="/ueber-uns" element={<UeberUns />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/agb" element={<AGB />} />
        </Routes>
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;